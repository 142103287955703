<template>
  <section 
    class="OnboardingPage"
  >
    <div class="OnboardingPage__content">
      <h1 class="OnboardingPage__title">Welcome to Bball.app</h1>
      <p class="OnboardingPage__body">Before we tip off, choose your look.</p>
      <ol 
        ref="themeListRef"
        class="OnboardingPage__themeList"
        @scroll="handleScroll"
      >
        <li
          v-for="theme in themes"
          :key="theme.value"
          class="OnboardingPage__themeItem"
          :class="{
            'OnboardingPage__themeItem--isSelected': theme.value === selectedTheme,
          }"
          :data-theme="theme.value"
          :style="`--themePrimary: var(--themePrimary${theme.value}); --themeSecondary: var(--themeSecondary${theme.value});`"
        >
          <button 
            class="OnboardingPage__themeItemButton" 
            @click="handleThemeClick"
          >
            <svg class="OnboardingPage__themeItemButtonSvg" width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="108" height="108" fill="var(--themePrimary)" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M52.0262 108L52.5 -0.5L54.5 -0.491266L54.0262 108.009L52.0262 108Z" fill="var(--themePrimaryForeground)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 53H107.5V55H0.5V53Z" fill="var(--themePrimaryForeground)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.5 96C21.696 96 40.5 77.196 40.5 54C40.5 30.804 21.696 12 -1.5 12C-24.696 12 -43.5 30.804 -43.5 54C-43.5 77.196 -24.696 96 -1.5 96ZM-1.5 98C22.8005 98 42.5 78.3005 42.5 54C42.5 29.6995 22.8005 10 -1.5 10C-25.8005 10 -45.5 29.6995 -45.5 54C-45.5 78.3005 -25.8005 98 -1.5 98Z" fill="var(--themePrimaryForeground)"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M108.5 96C131.696 96 150.5 77.196 150.5 54C150.5 30.804 131.696 12 108.5 12C85.304 12 66.5 30.804 66.5 54C66.5 77.196 85.304 96 108.5 96ZM108.5 98C132.801 98 152.5 78.3005 152.5 54C152.5 29.6995 132.801 10 108.5 10C84.1995 10 64.5 29.6995 64.5 54C64.5 78.3005 84.1995 98 108.5 98Z" fill="var(--themePrimaryForeground)"/>
            </svg>
            <span class="OnboardingPage__themeItemButtonLabel">{{ theme.label }}</span>
          </button>
        </li>
      </ol>
      <FormButton
        class="OnboardingPage__saveButton"
        :disabled="isSaving"
        @click="handleSaveClick"
      >Save</FormButton>
    </div>
  </section>
</template>

<script lang="ts" setup>
import FormButton from '@/components/FormButton.vue';
import useTheme from '@/composables/useTheme';
import { Database } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import { RouteName } from '@/lib/router/routes';
import useAuthStore from '@/stores/useAuthStore';
import { storeToRefs } from 'pinia';
import { onMounted, ref, useTemplateRef } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()
const {
  currentUser,
  currentPlayer,
} = storeToRefs(useAuthStore())

const {
  isDarkColor,
  setTheme,
} = useTheme()

const themeListRef = useTemplateRef("themeListRef")
const isSaving = ref(false)
const selectedTheme = ref<Database["public"]["Enums"]["Theme"]>("LosAngelesLakers")

const themes = ref<Array<{
  label: string,
  value: Database["public"]["Enums"]["Theme"],
}>>([
  {
    label: "ATL",
    value: "AtlantaHawks",
  },
  {
    label: "BOS",
    value: "BostonCeltics",
  },
  {
    label: "BKN",
    value: "BrooklynNets",
  },
  {
    label: "CHA",
    value: "CharlotteHornets",
  },
  {
    label: "CHI",
    value: "ChicagoBulls",
  },
  {
    label: "CLE",
    value: "ClevelandCavaliers",
  },
  {
    label: "DAL",
    value: "DallasMavericks",
  },
  {
    label: "DEN",
    value: "DenverNuggets",
  },
  {
    label: "DET",
    value: "DetroitPistons",
  },
  {
    label: "GSW",
    value: "GoldenStateWarriors",
  },
  {
    label: "HOU",
    value: "HoustonRockets",
  },
  {
    label: "IND",
    value: "IndianaPacers",
  },
  {
    label: "LAC",
    value: "LosAngelesClippers",
  },
  {
    label: "LAL",
    value: "LosAngelesLakers",
  },
  {
    label: "MEM",
    value: "MemphisGrizzlies",
  },
  {
    label: "MIA",
    value: "MiamiHeat",
  },
  {
    label: "MIL",
    value: "MilwaukeeBucks",
  },
  {
    label: "MIN",
    value: "MinnesotaTimberwolves",
  },
  {
    label: "NOP",
    value: "NewOrleansPelicans",
  },
  {
    label: "NYK",
    value: "NewYorkKnicks",
  },
  {
    label: "OKC",
    value: "OklahomaCityThunder",
  },
  {
    label: "ORL",
    value: "OrlandoMagic",
  },
  {
    label: "PHI",
    value: "Philadelphia76ers",
  },
  {
    label: "PHX",
    value: "PhoenixSuns",
  },
  {
    label: "POR",
    value: "PortlandTrailBlazers",
  },
  {
    label: "SAC",
    value: "SacramentoKings",
  },
  {
    label: "SAS",
    value: "SanAntonioSpurs",
  },
  {
    label: "TOR",
    value: "TorontoRaptors",
  },
  {
    label: "UTA",
    value: "UtahJazz",
  },
  {
    label: "WAS",
    value: "WashingtonWizards",
  },
])

onMounted(() => {
  if (themeListRef.value) {
    themeListRef.value.querySelectorAll(".OnboardingPage__themeItem").forEach((itemEl) => {
      const style = getComputedStyle(itemEl);
      
      (itemEl as HTMLElement).style.setProperty("--themePrimaryForeground", isDarkColor(style.getPropertyValue("--themePrimary")) ? "var(--themeLightTransparent500)" : "var(--themeNeutral900)");
      (itemEl as HTMLElement).style.setProperty("--themeSecondaryForeground", isDarkColor(style.getPropertyValue("--themeSecondary")) ? "var(--themeNeutral0)" : "var(--themeNeutral900)");
    })

    const themeItemEl = themeListRef.value.querySelector(`.OnboardingPage__themeItem[data-theme='${selectedTheme.value}']`)

    themeItemEl?.scrollIntoView({
      behavior: "instant",
      inline: "center",
    })
  }
})

function handleThemeClick(e:MouseEvent) {
  const el = e.currentTarget as HTMLElement

  el.scrollIntoView({
    behavior: "smooth",
    inline: "center"
  })
}

function handleScroll() {
  if (!themeListRef.value) return

  const index = Math.round(themeListRef.value.scrollLeft / 114)
  selectedTheme.value = themes.value[index].value
  setTheme(selectedTheme.value)
}

async function handleSaveClick() {
  if (!currentUser.value) return;

  isSaving.value = true
  const {data: player, error} = await supabaseClient
    .from("players")
    .update({theme: selectedTheme.value})
    .eq("user_id", currentUser.value.id)
    .select()
    .maybeSingle()

  if (player) {
    currentPlayer.value = player
  } else if (error) {
    alert(error.message)
  }
  
  router.push({name: RouteName.index})
}
</script>

<style lang="scss" scoped>
.OnboardingPage {
  display: grid;
  place-items: center;
  height: 100%;
  color: var(--themeNeutral0);
  text-align: center;
}

.OnboardingPage__content {
  display: grid;
  gap: 24px;
  padding: 0 20px;
}

.OnboardingPage__title {
  font-size: 24px;
  font-stretch: var(--fontWidthExtended);
  font-weight: var(--fontWeightMedium);
}

.OnboardingPage__body {
  font-size: 18px;
  font-stretch: var(--fontWidthExtended);
}

.OnboardingPage__themeList {
  display: flex;
  gap: 14px;
  margin: 16px -20px;
  padding: 3px 23px;
  overflow-x: auto;
  mask-image: linear-gradient(
    to right,
    transparent 0,
    black 20px,
    black calc(100% - 20px),
    transparent 100%,
  );

  scroll-snap-type: x mandatory;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::before,
  &::after {
    min-width: calc(50% - 64px);
    content: "";
  }
}

.OnboardingPage__themeItem {
  scroll-snap-align: center;
  transition: 350ms opacity;
}

.OnboardingPage__themeItem:not(.OnboardingPage__themeItem--isSelected) {
  opacity: 0.5;
}

.OnboardingPage__themeItemButton {
  position: relative;
  isolation: isolate;
  width: 100px;
  aspect-ratio: 1;
  padding-top: 2px;
  border-radius: 50%;
  background-color: var(--themeNeutral900);
  color: var(--themeNeutral0);
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  line-height: 1;
  font-stretch: var(--fontWidthExtended);
  text-transform: uppercase;
  overflow: hidden;
  border: 2px solid var(--themeNeutral900);
  color: var(--themeNeutral900);
  box-shadow: 0 0 0 0 var(--themeLightTransparent0);
  transition: 350ms box-shadow;

  .OnboardingPage__themeItem--isSelected & {
    box-shadow: 0 0 0 3px var(--themeNeutral0);
  }
}

.OnboardingPage__themeItemButtonSvg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.OnboardingPage__themeItemButtonLabel {
  position: relative;
  isolation: isolate;
  color: var(--themeSecondaryForeground);

  &::before {
    position: absolute;
    inset: -5px -8px -3px;
    z-index: -1;
    border-radius: 9999px;
    background-color: var(--themeSecondary);
    content: "";
  }
}
</style>