import { Database } from "@/lib/database/database.types";
import { Hsluv } from "hsluv";

export default function() {
  function setTheme(theme: Database["public"]["Enums"]["Theme"] | null | undefined) {
    const localTheme = localStorage.getItem("theme")
    const _theme = theme || localTheme

    if (theme && !localTheme) {
      localStorage.setItem("theme", theme)
    }
    
    document.documentElement.style.setProperty("--themePrimary", `var(--themePrimary${_theme}, var(--themePrimaryLosAngelesLakers))`);
    document.documentElement.style.setProperty("--themeSecondary", `var(--themeSecondary${_theme}, var(--themeSecondaryLosAngelesLakers))`);
    
    const primaryColor = getComputedStyle(document.documentElement).getPropertyValue("--themePrimary")
    const secondaryColor = getComputedStyle(document.documentElement).getPropertyValue("--themeSecondary")
    document.documentElement.style.setProperty("--themeSecondaryForeground", isDarkColor(secondaryColor) ? "var(--themeNeutral0)" : "var(--themeNeutral900)");
    
    const themeColorMeta = document.head.querySelector("meta[name='theme-color']")
    themeColorMeta?.setAttribute("content", primaryColor)
  }

  function isDarkColor(color: string) {
    // YIQ equation from http://24ways.org/2010/calculating-color-contrast
    const hsluv = new Hsluv()
    hsluv.hex = color
    hsluv.hexToRgb();
    const [red, green, blue] = [hsluv.rgb_r, hsluv.rgb_g, hsluv.rgb_b].map((value) => value * 255);
    const yiq = (red * 299 + green * 587 + blue * 114) / 1000;
    
    return yiq < 128;
  }

  return {
    setTheme,
    isDarkColor,
  }
}