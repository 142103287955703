<template>
  <form 
    @submit.prevent="handleInviteUser"
  >
    <fieldset :disabled="isRequesting">
      <h2>Invite a player</h2>
      <label>
        Name
        <input 
          type="name"
          v-model="form.name"
          required
        >
      </label>
      <label>
        Email
        <input 
          type="email"
          v-model="form.email"
          required
        >
      </label>
      <label>
        Crews<br>
        <select 
          v-model="form.crewIds"
          multiple
          required
        >
          <option
            v-for="crew in crews"
            :key="crew.id"
            :value="crew.id"
          >{{ crew.name }}</option>
        </select>
      </label>
      <button
        type="submit"
      >
        <progress v-if="isRequesting" /><template v-else>Submit</template>
      </button>
    </fieldset>
  </form>
</template>

<script lang="ts" setup>
import { Tables } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import useAuthStore from '@/stores/useAuthStore';
import { User } from '@supabase/supabase-js';
import { storeToRefs } from 'pinia';
import { ref } from 'vue';

const {session} = storeToRefs(useAuthStore())

const form = ref(getInitialForm())
const isRequesting = ref(false)
const crews = ref<Array<Tables<"crews">>>([])

supabaseClient.from("crews").select().order("name").then(({data: _crews}) => crews.value = _crews || [])

function getInitialForm() {
  return {
    name: "",
    email: "",
    crewIds: [],
  }
}

async function handleInviteUser() {
  isRequesting.value = true
  try {
    const response = await fetch("/api/users/invite", {
      method: "POST",
      body: JSON.stringify(form.value),
      headers: {
        authorization: session.value!.access_token
      }
    })

    if (response.ok) {
      const data:{user: User} = await response.json()

      const {data: player} = await supabaseClient.from("players").select().eq("user_id", data.user.id).limit(1).maybeSingle()

      if (player) {
        await Promise.all(
          form.value.crewIds.map((crewId) => {
            return supabaseClient.from("player_crews").insert({
              crew_id: crewId,
              player_id: player.id,
            })
          })
        )
      }

      form.value = getInitialForm()
    } else {
      const {error} = await response.json()
      alert(error)
    }
  } catch (err) {
    alert((err as Error).message)
  }
  isRequesting.value = false
}
</script>

<style lang="scss" scoped>
form {
  padding: 16px;
  outline: solid 1px black;
}

h2 {
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
}

fieldset {
  display: grid;
  gap: 16px;
}

input {
  margin-top: 4px;
  padding: 8px;
  width: 100%;
}

button {
  padding: 8px;
  background-color: var(--themePrimary);
  color: white;
}
</style>