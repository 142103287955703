<template>
  <article 
    v-if="run"
    class="RunView"
  >
    <div class="RunView__headerContainer">
      <header class="RunView__header">
        <h6 class="RunView__crew">{{ formattedCrewNames }}</h6>
        <RouterLink
          class="RunView__datetimeLink"
          :to="{
            name: RouteName.run,
            params: {
              id: run.id,
            }
          }"
        >
          <h5 class="RunView__datetime">
            <time 
              class="RunView__date"
              :datetime="run.start_at"
            >{{ formattedDate }}</time>
            <span class="RunView__time">
              <time :datetime="run.start_at">{{ formattedStartTime }}</time><template v-if="run.end_at && formattedEndTime"> – <time :datetime="run.end_at">{{ formattedEndTime }}</time></template>
            </span>
          </h5>
        </RouterLink>
        <h6 class="RunView__court">
          <Icon
            class="RunView__courtIcon"
            name="location"
            size="medium"
          />
          <span class="RunView__courtName">{{ run.court?.name }}</span>
        </h6>
      </header>
      <div class="RunView__progress">
        <ol class="RunView__progressList">
          <li 
            v-for="(spot, i) in spots"
            :key="i"
            class="RunView__progressItem"
            :data-taken="spot.isTaken || undefined"
          ></li>
        </ol>
      </div>
    </div>
    <div class="RunView__content">
      <section class="RunView__statSection">
        <ul class="RunView__statList">
          <li class="RunView__statItem">
            <strong class="RunView__statValue">{{ inPlayers.length }}</strong>
            <span class="RunView__statLabel">in</span>
          </li>
          <li class="RunView__statItem">
            <strong class="RunView__statValue">{{ maybePlayers.length }}</strong>
            <span class="RunView__statLabel">maybe</span>
          </li>
          <li class="RunView__statItem">
            <strong class="RunView__statValue">{{ pendingPlayers.length }}</strong> 
            <span class="RunView__statLabel">pending</span>
          </li>
          <li 
            v-if="neededPlayerCount"
            class="RunView__statItem"
          >
            <strong class="RunView__statValue">{{ neededPlayerCount }}</strong> 
            <span class="RunView__statLabel">needed</span>
          </li>
          <li 
            v-else-if="openSpotCount"
            class="RunView__statItem"
          >
            <strong class="RunView__statValue">{{ openSpotCount }}</strong> 
            <span class="RunView__statLabel">spots left</span>
          </li>
        </ul>
      </section>

      <section 
        v-if="canUpdateStatus" 
        class="RunView__statusButtonList"
        :data-status-probability="currentPlayerStatus?.probability"
      >
        <button 
          class="RunView__statusButton" 
          :class="{
            'RunView__statusButton--isSelected': currentPlayerStatus?.probability === 1
          }"
          @click="currentPlayerStatus?.probability !== 1 ? handleUpdateStatus(1) : handleRemoveStatus(currentPlayerStatus)"
        >
          <svg class="RunView__statusButtonSvg" width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="108" height="108" fill="var(--colorOrange)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.0262 108L52.5 -0.5L54.5 -0.491266L54.0262 108.009L52.0262 108Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 53H107.5V55H0.5V53Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.5 96C21.696 96 40.5 77.196 40.5 54C40.5 30.804 21.696 12 -1.5 12C-24.696 12 -43.5 30.804 -43.5 54C-43.5 77.196 -24.696 96 -1.5 96ZM-1.5 98C22.8005 98 42.5 78.3005 42.5 54C42.5 29.6995 22.8005 10 -1.5 10C-25.8005 10 -45.5 29.6995 -45.5 54C-45.5 78.3005 -25.8005 98 -1.5 98Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M108.5 96C131.696 96 150.5 77.196 150.5 54C150.5 30.804 131.696 12 108.5 12C85.304 12 66.5 30.804 66.5 54C66.5 77.196 85.304 96 108.5 96ZM108.5 98C132.801 98 152.5 78.3005 152.5 54C152.5 29.6995 132.801 10 108.5 10C84.1995 10 64.5 29.6995 64.5 54C64.5 78.3005 84.1995 98 108.5 98Z"/>
          </svg>
          <span class="RunView__statusButtonLabel">In</span>
        </button>
        <button 
          class="RunView__statusButton" 
          :class="{
            'RunView__statusButton--isSelected': currentPlayerStatus?.probability === 0.5
          }"
          @click="currentPlayerStatus?.probability !== 0.5 ? handleUpdateStatus(0.5) : handleRemoveStatus(currentPlayerStatus)"
        >
          <svg class="RunView__statusButtonSvg" width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="54" height="108" fill="var(--colorOrange)" />
            <rect x="54" width="54" height="108" fill="var(--themeNeutral200)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.0262 108L52.5 -0.5L54.5 -0.491266L54.0262 108.009L52.0262 108Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 53H107.5V55H0.5V53Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.5 96C21.696 96 40.5 77.196 40.5 54C40.5 30.804 21.696 12 -1.5 12C-24.696 12 -43.5 30.804 -43.5 54C-43.5 77.196 -24.696 96 -1.5 96ZM-1.5 98C22.8005 98 42.5 78.3005 42.5 54C42.5 29.6995 22.8005 10 -1.5 10C-25.8005 10 -45.5 29.6995 -45.5 54C-45.5 78.3005 -25.8005 98 -1.5 98Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M108.5 96C131.696 96 150.5 77.196 150.5 54C150.5 30.804 131.696 12 108.5 12C85.304 12 66.5 30.804 66.5 54C66.5 77.196 85.304 96 108.5 96ZM108.5 98C132.801 98 152.5 78.3005 152.5 54C152.5 29.6995 132.801 10 108.5 10C84.1995 10 64.5 29.6995 64.5 54C64.5 78.3005 84.1995 98 108.5 98Z"/>
          </svg>
          <span class="RunView__statusButtonLabel">Maybe</span>
        </button>
        <button 
          class="RunView__statusButton" 
          :class="{
            'RunView__statusButton--isSelected': currentPlayerStatus?.probability === 0
          }"
          @click="currentPlayerStatus?.probability !== 0 ? handleUpdateStatus(0) : handleRemoveStatus(currentPlayerStatus)"
        >
          <svg class="RunView__statusButtonSvg" width="108" height="108" viewBox="0 0 108 108" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="108" height="108" fill="var(--themeNeutral200)" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M52.0262 108L52.5 -0.5L54.5 -0.491266L54.0262 108.009L52.0262 108Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 53H107.5V55H0.5V53Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.5 96C21.696 96 40.5 77.196 40.5 54C40.5 30.804 21.696 12 -1.5 12C-24.696 12 -43.5 30.804 -43.5 54C-43.5 77.196 -24.696 96 -1.5 96ZM-1.5 98C22.8005 98 42.5 78.3005 42.5 54C42.5 29.6995 22.8005 10 -1.5 10C-25.8005 10 -45.5 29.6995 -45.5 54C-45.5 78.3005 -25.8005 98 -1.5 98Z"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M108.5 96C131.696 96 150.5 77.196 150.5 54C150.5 30.804 131.696 12 108.5 12C85.304 12 66.5 30.804 66.5 54C66.5 77.196 85.304 96 108.5 96ZM108.5 98C132.801 98 152.5 78.3005 152.5 54C152.5 29.6995 132.801 10 108.5 10C84.1995 10 64.5 29.6995 64.5 54C64.5 78.3005 84.1995 98 108.5 98Z"/>
          </svg>
          <span class="RunView__statusButtonLabel">Out</span>
        </button>
      </section>

      <section class="RunView__playerGrid">
        <div 
          v-if="inPlayers.length > 0 || maybePlayers.length > 0 || waitlistPlayers.length > 0"
          class="RunView__playerColumn"
        >
          <PlayerList
            v-if="inPlayers.length > 0"
            iconName="check"
            label="In"
            :players="inPlayers"
            isActive
            includeCount
          />

          <PlayerList
            v-if="maybePlayers.length > 0"
            iconName="circleHalf"
            label="Maybe"
            isActive
            :players="maybePlayers"
          />

          <PlayerList
            v-if="waitlistPlayers.length > 0"
            iconName="circleClock"
            label="Waitlist"
            :players="waitlistPlayers"
            includeCount
          />
        </div>

        <div 
          v-if="outPlayers.length > 0 || pendingPlayers.length > 0"
          class="RunView__playerColumn"
        >
          <PlayerList
            v-if="outPlayers.length > 0"
            iconName="x"
            label="Out"
            :players="outPlayers"
          />

          <PlayerList
            v-if="pendingPlayers.length > 0"
            iconName="circleEmpty"
            label="Pending"
            :players="pendingPlayers"
          />
        </div>
      </section>
    </div>
  </article>
</template>

<script lang="ts" setup>
import Icon from '@/components/Icon.vue';
import PlayerList from '@/components/PlayerList.vue';
import { Tables } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import { RouteName } from '@/lib/router/routes';
import isTruthy from '@/lib/utils/boolean/isTruthy';
import useAuthStore from '@/stores/useAuthStore';
import useRunStore from '@/stores/useRunStore';
import { QueryData, RealtimeChannel, RealtimePostgresDeletePayload, RealtimePostgresInsertPayload, RealtimePostgresUpdatePayload } from '@supabase/supabase-js';
import { format, parseISO } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()
const {currentUser} = storeToRefs(useAuthStore())
const {runs} = storeToRefs(useRunStore())

const statusChannel = ref<RealtimeChannel | null>(null);

const fetchRunQuery = supabaseClient.from("runs").select(
  `*, 
  court:courts(*), 
  crews(*, players(*)), 
  statuses(*, player:players(*)),
  season:seasons(*, players(*))
`).limit(1)
const fetchStatusQuery = supabaseClient.from("statuses").select(`*, player:players(*)`).limit(1)
const existingRun = (():QueryData<typeof fetchRunQuery>[0] | null => {
  const run = runs.value.find((run) => run.id === route.params.id) || null

  if (run) {
    return {
      ...run,
      season: null,
      crews: run.crews.map((crew) => {
        return {
          ...crew,
          players: []
        }
      }),
      statuses: run.statuses.map((status) => {
        return {
          ...status,
          player: null,
        }
      })
    }
  }

  return null
})()
const run = ref(existingRun)

onBeforeMount(async () => {
  try {
    const {data: _run} = await fetchRunQuery.eq("id", route.params.id).maybeSingle()

    if (_run) run.value = _run
  } catch (err) {
    alert((err as Error).message)
  }

  statusChannel.value = supabaseClient
    .channel("statuses")
    .on("postgres_changes", { event: "INSERT", schema: "public", table: "statuses" }, handleStatusUpsert)
    .on("postgres_changes", { event: "UPDATE", schema: "public", table: "statuses" }, handleStatusUpsert)
    .on("postgres_changes", { event: "DELETE", schema: "public", table: "statuses" }, handleStatusDelete)
    .subscribe();
})

onBeforeUnmount(() => {
  statusChannel.value?.unsubscribe();
  statusChannel.value = null;
})

const startDate = computed(() => {
  if (!run.value) return

  return parseISO(run.value.start_at)
})
const formattedStartTime = computed(() => {
  if (!startDate.value) return

  return formatDate(startDate.value)
})

const endDate = computed(() => run.value?.end_at ? parseISO(run.value.end_at) : null)
const formattedEndTime = computed(() => formatDate(endDate.value))
const formattedDate = computed(() => {
  if (!startDate.value) return

  return format(startDate.value, "EEE, MMM d")
})
const formattedCrewNames = computed(() => run.value?.crews.map((crew) => crew.name).join(", "))

const inPlayers = computed(() => getPlayersByStatus(expectedPlayers.value, 1))
const maybePlayers = computed(() => getPlayersByStatus(statusPlayers.value, 0.5))
const outPlayers = computed(() => getPlayersByStatus(statusPlayers.value, 0))
const waitlistPlayers = computed(() => guestInPlayers.value)

const crewPlayers = computed(() => {
  return run.value?.crews.flatMap((crew) => crew.players) || []
})

const guestInPlayers = computed(() => {
  const expectedPlayerIds = expectedPlayers.value.map((player) => player.id)
  
  return (run.value?.statuses || [])
    .filter((status) => status.probability === 1)
    .sort((a, b) => +parseISO(a.created_at) - +parseISO(b.created_at))
    .map((status) => status.player)
    .filter(isTruthy)
    .filter((player) => !expectedPlayerIds.includes(player.id))
})

const expectedPlayers = computed(() => {
  return [...(run.value?.season?.players || crewPlayers.value)].sort((a, b) => a.name.localeCompare(b.name))
})

const statusPlayers = computed(() => {
  return run.value?.statuses.map((status) => status.player).filter(isTruthy) || []
})

const pendingPlayers = computed(() => {
  const statusPlayerIds = run.value?.statuses.map((status) => status.player_id) || []

  return expectedPlayers.value.filter((player) => !statusPlayerIds.includes(player.id))
})

const neededPlayerCount = computed(() => {
  if (!run.value) return

  return Math.max(0, (run.value.min_player_count || 0) - inPlayers.value.length)
})

const openSpotCount = computed(() => {
  if (!run.value) return

  return Math.max(0, (run.value.max_player_count || 0) - inPlayers.value.length)
})

const spots = computed(() => {
  if (!run.value) return []

  const defaultMinCount = 8
  const length = inPlayers.value.length < (run.value.min_player_count || 0)
    ? run.value.min_player_count || run.value.max_player_count || defaultMinCount
    : run.value.max_player_count || Math.max(inPlayers.value.length, run.value.min_player_count || defaultMinCount)

  return Array.from({length}).map((_, i) => {
    return {
      isTaken: i < inPlayers.value.length,
    }
  })
})

const canUpdateStatus = computed(() => !!currentPlayer.value)

const currentPlayer = computed(() => {
  if (!currentUser.value) return

  return crewPlayers.value.find((player) => player.user_id === currentUser.value?.id)
})

const currentPlayerStatus = computed(() => {
  if (!currentUser.value) return

  return run.value?.statuses.find((status) => status.player?.user_id === currentUser.value?.id)
})

function getPlayersByStatus(players:Array<Tables<"players">>, probability:number) {
  if (!run.value) return []

  const playerIds = players.map((player) => player.id)

  return run.value.statuses
    .filter((status) => status.probability === probability && playerIds.includes(status.player_id))
    .map((status) => status.player)
    .filter(isTruthy)
}

function formatDate(date:Date | null):string | null {
  if (!date) return null

  return format(date, date.getMinutes() > 0 ? "h:mmaaa" : "haaa")
}

async function handleUpdateStatus(probability: number) {
  if (!run.value || !currentPlayer.value) return;

  const {data:status} = await supabaseClient.from("statuses").upsert({
    run_id: run.value.id,
    player_id: currentPlayer.value.id,
    probability,
  }).select().maybeSingle()
  
  const existingStatus = run.value.statuses.find((_status) => _status.player_id === currentPlayer.value?.id)
  if (existingStatus) {
    Object.assign(existingStatus, status)
  } else if (status) {
    run.value.statuses.push({
      ...status,
      player: currentPlayer.value
    })
  }
}

async function handleRemoveStatus(_status:Tables<"statuses">) {
  const {data:status} = await supabaseClient
    .from("statuses")
    .delete()
    .eq("run_id", _status.run_id)
    .eq("player_id", _status.player_id)
    .select("*")
    .maybeSingle()

  if (status) removeStatus(status)
}

async function addOrReplaceStatus(_status:Tables<"statuses">) {
  const {data:status} = await fetchStatusQuery.eq("run_id", _status.run_id).eq("player_id", _status.player_id).maybeSingle()

  if (!status || !run.value) return;
  const existingStatus = run.value.statuses.find((_status) => _status.run_id === status.run_id && _status.player_id == status.player_id)

  if (existingStatus) {
    Object.assign(existingStatus, status)
  } else {
    run.value.statuses.push(status)
  }
}

function removeStatus(status:Partial<Tables<"statuses">>) {
  if (!run.value) return;
  const index = run.value.statuses.findIndex((_status) => _status.run_id === status.run_id && _status.player_id === status.player_id)
  
  if (index !== -1) run.value.statuses.splice(index, 1)
}

function handleStatusUpsert (payload:RealtimePostgresInsertPayload<Tables<"statuses">> | RealtimePostgresUpdatePayload<Tables<"statuses">>) {
  addOrReplaceStatus(payload.new)
}

function handleStatusDelete (payload:RealtimePostgresDeletePayload<Tables<"statuses">>) {
  removeStatus(payload.old)
}
</script>

<style lang="scss" scoped>
.RunView {
  --spacing: 20px;
  background-color: var(--colorNeutral0);
  min-height: 100%;
}

.RunView__headerContainer {
  display: grid;
  gap: 24px;
  padding: 20px var(--spacing) 24px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
  background-color: var(--themePrimary);
  color: var(--colorNeutral0);
}

.RunView__header {
  display: grid;
  gap: 16px;
}

.RunView__crew {
  font-size: 13px;
  color: var(--themeLightTransparent700);
}

.RunView__datetime {
  display: grid;
  gap: 8px;
}

.RunView__date {
  font-size: 40px;
}

.RunView__time {
  font-size: 24px;
}

.RunView__court {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 6px;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  color: var(--themeLightTransparent700);
}

.RunView__courtName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RunView__progress {
  position: relative;
  margin: 3px;
  border-radius: 9999px;
  border: solid 2px var(--themeNeutral900);
  background-color: var(--themeNeutral0);
  box-shadow: 0 0 0 3px var(--themeNeutral0);
  overflow: hidden;
}

.RunView__progressList {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  height: 44px;
}

.RunView__progressItem {
  &[data-taken] {
    background-color: var(--themeSecondary);

    & + & {
      border-left: dashed 1px var(--themeDarkTransparent200);
    }
  }

  &:not([data-taken]) + & {
    border-left: dashed 1px var(--themeNeutral150);
  }
}

.RunView__content {
  display: grid;
  isolation: isolate;
  background-color: var(--themeNeutral0);
}

.RunView__statusButtonList {
  display: grid;
  justify-items: center;
  gap: 12px;
  grid-template-columns: repeat(3, 1fr);
  padding: 20px;
  border-bottom: dashed 1px var(--themeNeutral150);
}

.RunView__statusButton {
  position: relative;
  isolation: isolate;
  width: 100%;
  max-width: 108px;
  aspect-ratio: 1;
  padding-top: 2px;
  border-radius: 50%;
  background-color: var(--themeNeutral900);
  color: var(--themeNeutral0);
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  line-height: 1;
  font-stretch: var(--fontWidthExtended);
  text-transform: uppercase;
  overflow: hidden;

  &:not(.RunView__statusButton--isSelected) {
    outline: 2px solid var(--themeNeutral0);
    outline-offset: -6px;
  }

  &.RunView__statusButton--isSelected {
    border: 2px solid var(--themeNeutral900);
    color: var(--themeNeutral900);
  }
}

.RunView__statusButtonSvg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  path {
    fill: var(--themeNeutral900);
  }

  .RunView__statusButton:not(.RunView__statusButton--isSelected) & {
    display: none;
  }
}

.RunView__statusButtonLabel {
  position: relative;
  isolation: isolate;

  &::before {
    position: absolute;
    inset: -5px -8px -3px;
    z-index: -1;
    border-radius: 9999px;
    background-color: var(--themeNeutral0);
    content: "";
  }

  .RunView__statusButton:not(.RunView__statusButton--isSelected) &::before {
    display: none;
  }
}

.RunView__statList {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  border-bottom: dashed 1px var(--themeNeutral150);
}

.RunView__statItem {
  display: grid;
  justify-items: center;
  align-content: center;
  gap: 6px;
  height: 88px;
  font: var(--fontFamilyMono);
  font-weight: var(--fontWeightMedium);

  & + & {
    border-left: dashed 1px var(--themeNeutral150);
  }
}

.RunView__statValue {
  font-family: var(--fontFamilyMono);
  font-size: 32px;
}

.RunView__statLabel {
  font-size: 14px;
  text-transform: uppercase;
}

.RunView__playerGrid {
  display: grid;
  gap: var(--spacing);
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  padding: var(--spacing);
}

.RunView__playerColumn {
  display: grid;
  gap: inherit;
  align-content: start;
}
</style>