<template>
  <article 
    class="TimelineRunItem"
  >
    <header class="TimelineRunItem__header">
      <h6 class="TimelineRunItem__crew"
      >{{ formattedCrewNames }}</h6>
      <RouterLink
        class="TimelineRunItem__time"
        :to="{
          name: RouteName.run,
          params: {
            id: modelValue.run.id,
          }
        }"
      >
        <h5>
          <time :datetime="modelValue.run.start_at">{{ formattedStartTime }}</time><template v-if="modelValue.run.end_at && formattedEndTime">&thinsp;–&thinsp;<time :datetime="modelValue.run.end_at">{{ formattedEndTime }}</time></template>
        </h5>
      </RouterLink>
      <h6 class="TimelineRunItem__court">
        <Icon
          class="TimelineRunItem__courtIcon"
          name="location"
          size="medium"
        />
        <span class="TimelineRunItem__courtName">{{ modelValue.court.name }}</span>
      </h6>
    </header>
    <footer class="TimelineRunItem__footer">
      <button 
        class="TimelineRunItem__footerButton"
        :class="{
          'TimelineRunItem__footerButton--isActive': currentPlayerStatus?.probability === 1
        }"
        :disabled="!currentPlayer"
        @click="handleButtonClick({probability: 1})"
      >
        <Icon 
          class="TimelineRunItem__footerButtonIcon"
          name="check" 
          size="medium"
        /> 
        <span class="TimelineRunItem__footerButtonLabel">{{ inCount }}</span>
      </button>
      <button 
        class="TimelineRunItem__footerButton"
        :class="{
          'TimelineRunItem__footerButton--isActive': currentPlayerStatus?.probability === 0.5
        }"
        :disabled="!currentPlayer"
        @click="handleButtonClick({probability: 0.5})"
      >
        <Icon 
          class="TimelineRunItem__footerButtonIcon"
          name="circleHalf" 
          size="medium"
        />
        <span class="TimelineRunItem__footerButtonLabel">{{ maybeCount }}</span>
      </button>
      <button 
        class="TimelineRunItem__footerButton"
        :class="{
          'TimelineRunItem__footerButton--isActive': currentPlayerStatus?.probability === 0
        }"
        :disabled="!currentPlayer"
        @click="handleButtonClick({probability: 0})"
      >
        <Icon 
          class="TimelineRunItem__footerButtonIcon"
          name="x" 
          size="medium"
        />
        <span class="TimelineRunItem__footerButtonLabel">{{ outCount }}</span>
      </button>
    </footer>
  </article>
</template>

<script lang="ts" setup>
import Icon from '@/components/Icon.vue';
import { RunItem } from '@/components/TimelineRunItem.types';
import { supabaseClient } from '@/lib/database/supabase';
import { RouteName } from '@/lib/router/routes';
import useAuthStore from '@/stores/useAuthStore';
import { format, parseISO } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const {currentPlayer} = storeToRefs(useAuthStore())

const modelValue = defineModel<RunItem>({required: true})

const formattedCrewNames = computed(() => {
  return modelValue.value.crews.map((crew) => crew.name).join(", ")
})

const startDate = computed(() => parseISO(modelValue.value.run.start_at))
const formattedStartTime = computed(() => formatDate(startDate.value))

const endDate = computed(() => modelValue.value.run.end_at ? parseISO(modelValue.value.run.end_at) : null)
const formattedEndTime = computed(() => formatDate(endDate.value))

const inCount = computed(() => modelValue.value.statuses.filter((status) => status.probability === 1).length)
const maybeCount = computed(() => modelValue.value.statuses.filter((status) => status.probability === 0.5).length)
const outCount = computed(() => modelValue.value.statuses.filter((status) => status.probability === 0).length)

const currentPlayerStatus = computed(() => {
  if (!currentPlayer.value) return null

  return modelValue.value.statuses.find((status) => status.player_id === currentPlayer.value?.id)
})

function formatDate(date:Date | null):string | null {
  if (!date) return null

  return format(date, date.getMinutes() > 0 ? "h:mmaaa" : "haaa")
}

async function handleButtonClick(e:{probability: number}) {
  if (!currentPlayer.value) return;

  try {
    if (!currentPlayerStatus.value) {
      const {data:status} = await supabaseClient.from("statuses")
        .insert({
          player_id: currentPlayer.value.id,
          run_id: modelValue.value.run.id,
          probability: e.probability,
        })
        .select("*")
        .maybeSingle()
  
      if (status) {
        modelValue.value.statuses.push(status)
      }
    } else if (currentPlayerStatus.value.probability === e.probability) {
      const {data:status} = await supabaseClient.from("statuses")
        .delete()
        .eq("run_id", modelValue.value.run.id)
        .eq("player_id", currentPlayer.value.id)
        .select("*")
        .maybeSingle()
  
      if (status) {
        modelValue.value.statuses.splice(modelValue.value.statuses.indexOf(status), 1)
      }
    } else if (currentPlayerStatus.value.probability !== e.probability) {
      const {data:status} = await supabaseClient.from("statuses")
        .update({probability: e.probability})
        .eq("run_id", modelValue.value.run.id)
        .eq("player_id", currentPlayer.value.id)
        .select("*")
        .maybeSingle()
  
      if (status) {
        Object.assign(currentPlayerStatus.value, status)
      }
    }
  } catch(err) {
    alert(`Error: ${JSON.stringify(err)}`)
  }
}
</script>

<style lang="scss" scoped>
.TimelineRunItem {
  display: grid;
  gap: 16px;
  padding: 19px 16px 15px;
  position: relative;
  outline: dashed 1px var(--themeNeutral100);
  background-color: var(--themeNeutral0);
}

.TimelineRunItem__header {
  display: grid;
}

.TimelineRunItem__crew {
  font-size: 13px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TimelineRunItem__time {
  margin-top: 12px;
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
}

.TimelineRunItem__court {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: center;
  gap: 6px;
  margin-top: 10px;
}

.TimelineRunItem__courtIcon {
  color: var(--themeNeutral300)
}

.TimelineRunItem__courtName {
  position: relative;
  top: 1px;
  font-size: 18px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.TimelineRunItem__footer {
  display: grid;
  grid-auto-flow: column;
}

.TimelineRunItem__footerButton {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: var(--fontFamilyMono);
  font-size: 15px;
  color: var(--themeNeutral500);
}

.TimelineRunItem__footerButton--isActive {
  color: var(--colorOrange);
}

.TimelineRunItem__footerButtonIcon {
  color: var(--themeNeutral400);

  .TimelineRunItem__footerButton--isActive & {
    color: var(--colorOrange);
  }
}

.TimelineRunItem__footerButtonLabel {
  position: relative;
  top: 1px;
}
</style>
