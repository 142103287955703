<template>
  <section>
    Logging out…
  </section>
</template>

<script lang="ts" setup>
import useAuthStore from '@/stores/useAuthStore';

const {signOut} = useAuthStore()

signOut()
</script>

<style lang="scss" scoped>
</style>