import { RouteName, routes } from "@/lib/router/routes";
import useAuthStore from "@/stores/useAuthStore";
import { storeToRefs } from "pinia";
import { createRouter as createVueRouter, createWebHistory } from "vue-router";

export function createRouter () {
  const router = createVueRouter({
    history: createWebHistory(),
    routes,
  });

  router.beforeEach(async (to) => {
    const authStore = useAuthStore()
    const {
      isSignedIn,
      currentPlayer,
    } = storeToRefs(authStore)

    await authStore.waitForSession()
    
    if (!isSignedIn.value && to.name !== RouteName.login) {
      return { name: RouteName.login }
    } else if (isSignedIn.value && to.name === RouteName.login) {
      return { name: RouteName.index }
    } else if (isSignedIn.value && to.name !== RouteName.onboarding) {
      await authStore.waitForPlayer()
      
      if (!currentPlayer.value?.theme) {
        return { name: RouteName.onboarding }
      }
    }
  })

  return router;
}
