<template>
  <button class="FormButton">
    <slot />
  </button>
</template>

<script lang="ts" setup>
</script>

<style lang="scss" scoped>
.FormButton {
  position: relative;
  width: 100%;
  padding: 18px 16px;
  font: var(--fontWeightMedium) 18px / 1 var(--fontFamilyNormal);
  font-stretch: var(--fontWidthExtended);
  border-radius: 11px;
  outline: 2px solid var(--themeNeutral900);
  outline-offset: -5px;
  background-color: var(--themeSecondary);
  color: var(--themeSecondaryForeground);
  box-shadow: 0 0 0 3px var(--themeNeutral0) inset;
  transition: 350ms;
  transition-property: background-color, color;

  &:focus {
    box-shadow: 0 0 0 3px var(--themeSecondary) inset;
  }

  &:disabled {
    background-color: var(--themeLightTransparent300);
    box-shadow: 0 0 0 3px var(--themeLightTransparent400) inset;
    color: var(--themeDarkTransparent600);
    outline-color: var(--themeDarkTransparent600);
  }
}
</style>
