<template>
  <section 
    class="PlayerList"
    :class="{
      'PlayerList--includeCount': includeCount,
      'PlayerList--isActive': isActive,
    }"
  >
    <h3 class="PlayerList__header">
      <Icon
        v-if="iconName"
        class="PlayerList__headerIcon"
        :name="iconName"
        size="medium"
      />
      <span class="PlayerList__headerLabel">{{ label }}</span>
    </h3>
  
    <ol class="PlayerList__list">
      <li 
        v-for="player in players"
        :key="player.id"
        class="PlayerList__item"
      >{{ formatPlayerName(player.name) }}</li>
      <li 
        v-if="players.length === 0"
        class="PlayerList__item PlayerList__item--placeholder"
      >
        &nbsp;
      </li>
    </ol>
  </section>
</template>

<script lang="ts" setup>
import { IconName } from '@/components/Icon.types';
import Icon from '@/components/Icon.vue';
import { Tables } from '@/lib/database/database.types';

defineProps<{
  iconName: keyof typeof IconName,
  label: string,
  players: Array<Pick<Tables<"players">, "id" | "name">>,
  isActive?: boolean,
  includeCount?: boolean,
}>()

function formatPlayerName(name: string) {
  return name.split(" ").map((str, i) => {
    if (i === 0) return str

    return str.charAt(0)
  }).join(" ")
}
</script>

<style lang="scss" scoped>
.PlayerList__header {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 32px;
  padding: 0 16px 0 12px;
  background-color: var(--themeNeutral200);

  .PlayerList--isActive & {
    background-color: var(--themeSecondary);
    color: var(--themeSecondaryForeground);
  }
}

.PlayerList__headerLabel {
  position: relative;
  top: 1px;
  font-size: 14px;
  font-weight: var(--fontWeightMedium);
  text-transform: uppercase;
}

.PlayerList__list {
  display: grid;
  border: solid 1px var(--themeNeutral100);
  border-top: none;
}

.PlayerList__item {
  padding: 12px;
  font-family: var(--fontFamilyMono);
  font-size: 20px;
  font-weight: var(--fontWeightMedium);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .PlayerList--includeCount & {
    list-style: decimal inside; 
  }

  & + & {
    border-top: dashed 1px var(--themeNeutral150);
  }
}

.PlayerList__item--placeholder {
  font-family: var(--fontFamilyMono);
  font-style: italic;
  color: var(--themeNeutral400)
}
</style>