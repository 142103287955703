import AppLayout from "@/layouts/AppLayout.vue";
import AccountPage from "@/pages/AccountPage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import LogoutPage from "@/pages/LogoutPage.vue";
import OnboardingPage from "@/pages/OnboardingPage.vue";
import AcceptRunInviteView from "@/views/AcceptRunInviteView.vue";
import ClaimPlayerView from "@/views/ClaimPlayerView.vue";
import CommissionerRunView from "@/views/CommissionerRunView.vue";
import CommissionerView from "@/views/CommissionerView.vue";
import RunView from "@/views/RunView.vue";
import ScheduleView from "@/views/ScheduleView.vue";
import SpreadsheetView from "@/views/SpreadsheetView.vue";
import { RouteRecordRaw } from "vue-router";

export enum RouteName {
  account = "account",
  commish = "commish",
  commishRun = "commishRun",
  claimPlayer = "claimPlayer",
  index = "index",
  login = "login",
  logout = "logout",
  onboarding = "onboarding",
  run = "run",
  runInviteAccept = "runInviteAccept",
  spreadsheet = "spreadsheet",
  schedule = "schedule",
}

export const routes:Array<RouteRecordRaw> = [
  {
    name: RouteName.login,
    path: "/login",
    component: LoginPage,
  },
  {
    name: RouteName.logout,
    path: "/logout",
    component: LogoutPage,
  },
  {
    name: RouteName.index,
    path: "",
    redirect: {
      name: RouteName.schedule,
    }
  },
  {
    name: RouteName.onboarding,
    path: "/onboarding",
    component: OnboardingPage,
  },
  {
    name: RouteName.commish,
    path: "/commish",
    component: CommissionerView,
  },
  {
    name: RouteName.commishRun,
    path: "/commish/runs/:runId",
    component: CommissionerRunView,
  },
  {
    name: RouteName.spreadsheet,
    path: "/spreadsheet",
    component: SpreadsheetView,
  },
  {
    name: RouteName.runInviteAccept,
    path: "/run-invites/:runId/:playerId/accept",
    component: AcceptRunInviteView,
  },
  {
    name: RouteName.claimPlayer,
    path: "/players/:id/claim",
    component: ClaimPlayerView,
  },
  {
    path: "",
    component: AppLayout,
    children: [
      {
        name: RouteName.schedule,
        path: "/schedule",
        component: ScheduleView,
      },
      {
        name: RouteName.run,
        path: "/runs/:id",
        component: RunView,
      },
      {
        name: RouteName.account,
        path: "/account",
        component: AccountPage,
      },
    ]
  }
]