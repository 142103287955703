<template>
  <section 
    class="TimelineRunGroup"
    :data-datetime="datetime"
  >
    <time 
      class="TimelineRunGroup__date"
      data-testid="date"
      v-bind="{datetime}"
    >
      <span class="TimelineRunGroup__dateDate">{{ formattedDate }}</span>
      <strong class="TimelineRunGroup__dateDayOfWeek">{{ formattedDayOfWeek }}</strong>
    </time>
    <div class="TimelineRunGroup__list">
      <slot />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { listHeaderHeight } from '@/components/ListHeader.types';
import { format } from 'date-fns';
import { computed } from 'vue';

const {
  date,
} = defineProps<{
  date: Date
}>()

const formattedDate = computed(() => format(date, "M/d"))
const formattedDayOfWeek = computed(() => format(date, "EEE"))
const datetime = computed(() => date.toISOString())
</script>

<style lang="scss" scoped>
.TimelineRunGroup {
  display: grid;
  grid-template-columns: 64px auto;
  align-items: start;
  outline: solid 1px var(--themeNeutral100);
  background-color: var(--themeNeutral0);
}

.TimelineRunGroup__date {
  position: sticky;
  top: calc(1px * v-bind(listHeaderHeight));
  display: grid;
  gap: 12px;
  padding-block: 19px 75px;
  text-align: center;
}

.TimelineRunGroup__dateDate {
  font-size: 13px;
  font-weight: var(--fontWeightMedium);
  font-stretch: var(--fontWidthExtended);
}

.TimelineRunGroup__dateDayOfWeek {
  font-size: 24px;
  font-weight: var(--fontWeightMedium);
}

.TimelineRunGroup__list {
  display: grid;
  gap: 1px;
}
</style>