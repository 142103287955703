<template>
  <input 
    class="FormInput"
    v-model="modelValue"
  >
</template>

<script lang="ts" setup>
const modelValue = defineModel<string>({
  required: true
})
</script>

<style lang="scss" scoped>
.FormInput {
  position: relative;
  width: 100%;
  padding: 16px 16px 15px;
  font: var(--fontWeightMedium) 18px / 1 var(--fontFamilyMono);
  border-radius: 11px;
  outline: 2px solid var(--themeNeutral900);
  outline-offset: -5px;
  background-color: var(--themeNeutral0);

  &:focus {
    box-shadow: 0 0 0 3px var(--themeSecondary) inset;
  }

  &:disabled {
    background-color: var(--themeLightTransparent600);
    outline-color: var(--themeDarkTransparent600);

    &::placeholder {
      color: var(--themeDarkTransparent400);
    }
  }
}
</style>