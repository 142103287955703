<template>
  <section class="AppLayout">
    <header class="AppLayout__header">
      <RouterLink 
        class="AppLayout__title"
        :to="{name: RouteName.schedule}"
      >
        <strong>
          <b>Bball</b>.app
        </strong>
      </RouterLink>
      <RouterLink 
        class="AppLayout__logo"
        :to="{name: RouteName.index}"
      >
        <Logo />
      </RouterLink>
      <RouterLink 
        class="AppLayout__accountLink"
        :to="{name: RouteName.account}"
      >
        <Icon
          name="person"
        />
      </RouterLink>
    </header>
    <main class="AppLayout__main">
      <RouterView />
    </main>
  </section>
</template>

<script lang="ts" setup>
import Icon from '@/components/Icon.vue';
import Logo from '@/components/Logo.vue';
import useTheme from '@/composables/useTheme';
import { RouteName } from '@/lib/router/routes';
import useAuthStore from '@/stores/useAuthStore';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';
import { RouterLink } from 'vue-router';

const {theme} = storeToRefs(useAuthStore())
const {setTheme} = useTheme()

watch(theme, () => {
  setTheme(theme.value)
}, {
  immediate: true
})
</script>

<style lang="scss" scoped>
:global(body) {
  overflow: hidden;
  overscroll-behavior: contain;
}

.AppLayout {
  display: grid;
  padding-top: 68px;
  height: 100%;
}

.AppLayout__header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  align-items: center; 
  padding: 16px; 
  background-color: var(--themePrimary);
  color: var(--themeLightTransparent700);
}

.AppLayout__title {
  font-size: 20px;

  strong {
    font-weight: var(--fontWeightLight);
  }

  b {
    font-weight: var(--fontWeightMedium);
    font-stretch: var(--fontWidthExtended);
  }

  &.router-link-active {
    color: var(--themeNeutral0);
  }
}

.AppLayout__logo {
  justify-self: center;
}

.AppLayout__accountLink {
  justify-self: end;
  color: inherit;

  &.router-link-active {
    color: var(--themeNeutral0);
  }
}

.AppLayout__main {
  overflow-y: auto;
}
</style>