import { Tables } from "@/lib/database/database.types";
import { supabaseClient } from "@/lib/database/supabase";
import { Session, SignInWithOAuthCredentials, SignInWithPasswordlessCredentials, Subscription } from "@supabase/supabase-js";
import { defineStore } from "pinia";
import { computed, ref, watch } from "vue";

export default defineStore("AuthStore", () => {
  const isSessionLoaded = ref(false)
  const session = ref<Session | null>(null)
  const subscription = ref<Subscription>()
  const isPlayerLoaded = ref(false)
  const currentPlayer = ref<Tables<"players"> | null>(null)

  const currentUser = computed(() => session.value?.user)
  const isSignedIn = computed(() => !!currentUser.value)
  const theme = computed(() => currentPlayer.value?.theme)

  watch(currentUser, async () => {
    if (currentUser.value) {
      const {data: player} = await supabaseClient.from("players").select("*").eq("user_id", currentUser.value.id).limit(1).maybeSingle()
  
      currentPlayer.value = player || null

      if (player) {
        isPlayerLoaded.value = true
      }
    } else {
      currentPlayer.value = null
      isPlayerLoaded.value = false
    }
  }, {
    immediate: true,
  })

  async function waitForSession() {
    return new Promise((resolve) => {
      if (isSessionLoaded.value) {
        resolve(session.value);
        return;
      }

      const cancelWatch = watch(isSessionLoaded, () => {
        if (isSessionLoaded) {
          cancelWatch()
          resolve(session.value);
        }
      })
    })
  }

  async function waitForPlayer() {
    return new Promise((resolve) => {
      if (isPlayerLoaded.value) {
        resolve(currentPlayer.value);
        return;
      }

      const cancelWatch = watch(isPlayerLoaded, () => {
        if (isPlayerLoaded) {
          cancelWatch()
          resolve(currentPlayer.value);
        }
      })
    })
  }
  
  async function subscribeToAuthChanges() {
    const { data } = await supabaseClient.auth.onAuthStateChange((event, _session) => {
      session.value = _session
      
      if (event === "INITIAL_SESSION") {
        isSessionLoaded.value = true
      }
    })

    subscription.value = data.subscription
  }

  function unsubscribeFromAuthChanges() {
    subscription.value?.unsubscribe()
  }
  
  async function fetchSession() {
    const {data:{session: _session}} = await supabaseClient.auth.getSession()
    session.value = _session
  }

  async function signInWithEmail(email:string, options:SignInWithPasswordlessCredentials["options"] = {}) {
    return supabaseClient.auth.signInWithOtp({
      email,
      options,
    })
  }

  async function signInWithGoogle(options:Omit<SignInWithOAuthCredentials, "provider">["options"] = {}) {
    return supabaseClient.auth.signInWithOAuth({
      provider: 'google',
      options,
    })
  }

  async function signOut() {
    const {error} = await supabaseClient.auth.signOut()

    if (!error) {
      session.value = null
    }
  }

  return {
    session,
    currentUser,
    currentPlayer,
    isSignedIn,
    theme,
    
    subscribeToAuthChanges,
    unsubscribeFromAuthChanges,
    fetchSession,
    signInWithEmail,
    signInWithGoogle,
    signOut,
    waitForSession,
    waitForPlayer,
  }
})