<template>
  <section class="AccountView">
    <FormButton @click="handleSignOutClick">Sign out</FormButton>
  </section>
</template>

<script lang="ts" setup>
import FormButton from '@/components/FormButton.vue';
import useAuthStore from '@/stores/useAuthStore';

const {signOut} = useAuthStore()

async function handleSignOutClick() {
  try {
    await signOut()
  } catch (err) {
    alert((err as Error).message)
  }
}
</script>

<style lang="scss" scoped>
.AccountView {
  display: grid;
  align-items: end;
  height: 100%;
  padding: 20px;
}
</style>