<template>
  <section class="LoginPage">
    <div class="LoginPage__container">
      <header class="LoginPage__header">
        <Logo class="LoginPage__logo" />
        <h1 class="LoginPage__title">
          <b>Bball</b>.app
        </h1>
        <p class="LoginPage__subtitle">Because ball is life.</p>
      </header>
      <form 
        class="LoginPage__form"
        @submit.prevent="handleSignIn"
      >
        <fieldset 
          class="LoginPage__fieldset"
          :disabled="isRequesting || isEmailSent"
        >
          <FormInput 
            v-model="email"
            type="email"
            name="email"
            autocomplete="email"
            placeholder="lebron@lakers.com" 
            required
          />
          <FormButton type="submit">
            <span v-if="isEmailSent">Email sent. Check your inbox.</span>
            <span v-else-if="isSendingEmail">Sending email…</span>
            <span v-else>Sign in</span>
          </FormButton>
        </fieldset>
      </form>
      <footer class="LoginPage__footer">
        <p>or, sign in using…</p>
        <button 
          class="LoginPage__oauthButton"
          :disabled="isRequesting"
          @click="handleGoogleClick"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <title>Google</title>
            <path d="M22.56 12.25C22.56 11.47 22.49 10.72 22.36 10H12V14.26H17.92C17.66 15.63 16.88 16.79 15.71 17.57V20.34H19.28C21.36 18.42 22.56 15.6 22.56 12.25Z" fill="var(--fillColor, #4285F4)"/>
            <path d="M11.9997 23C14.9697 23 17.4597 22.02 19.2797 20.34L15.7097 17.57C14.7297 18.23 13.4797 18.63 11.9997 18.63C9.13969 18.63 6.70969 16.7 5.83969 14.1H2.17969V16.94C3.98969 20.53 7.69969 23 11.9997 23Z" fill="var(--fillColor, #34A853)"/>
            <path d="M5.84 14.0899C5.62 13.4299 5.49 12.7299 5.49 11.9999C5.49 11.2699 5.62 10.5699 5.84 9.90995V7.06995H2.18C1.43 8.54995 1 10.2199 1 11.9999C1 13.7799 1.43 15.4499 2.18 16.9299L5.03 14.7099L5.84 14.0899Z" fill="var(--fillColor, #FBBC05)"/>
            <path d="M11.9997 5.38C13.6197 5.38 15.0597 5.94 16.2097 7.02L19.3597 3.87C17.4497 2.09 14.9697 1 11.9997 1C7.69969 1 3.98969 3.47 2.17969 7.07L5.83969 9.91C6.70969 7.31 9.13969 5.38 11.9997 5.38Z" fill="var(--fillColor, #EA4335)"/>
          </svg>
        </button>
      </footer>
    </div>
  </section>
</template>

<script lang="ts" setup>
import FormButton from '@/components/FormButton.vue';
import FormInput from '@/components/FormInput.vue';
import Logo from '@/components/Logo.vue';
import { RouteName } from '@/lib/router/routes';
import useAuthStore from '@/stores/useAuthStore';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()

const {
  signInWithEmail,
  signInWithGoogle,
} = useAuthStore()

const email = ref("")
const isEmailSent = ref(false)
const isSendingEmail = ref(false)
const isRedirecting = ref(false)

const isRequesting = computed(() => isSendingEmail.value || isRedirecting.value)

async function handleSignIn() {
  isSendingEmail.value = true
  const {error} = await signInWithEmail(email.value, {
    shouldCreateUser: false, 
    emailRedirectTo: import.meta.env.VITE_APP_URL + router.resolve({name: RouteName.schedule}).fullPath
  })
  isSendingEmail.value = false
  
  if (!error) {
    isEmailSent.value = true
  } else {
    alert(error.message)
  }
}

async function handleGoogleClick() {
  isRedirecting.value = true
  await signInWithGoogle()
}
</script>

<style lang="scss" scoped>
.LoginPage {
  display: grid;
  place-items: center;
  height: 100%;
  background-color: var(--themePrimary);
  color: var(--themeNeutral0);
}

.LoginPage__container {
  display: grid;
  padding: 16px;
}

.LoginPage__header {
  display: grid;
  text-align: center;
}

.LoginPage__logo {
  margin: 0 auto 16px;
}

.LoginPage__title {
  font: var(--fontWeightLight) 40px / normal var(--fontFamilyNormal);

  b {
    font-weight: var(--fontWeightMedium);
    font-stretch: var(--fontWidthExtended);
  }
}

.LoginPage__subtitle {
  margin-top: 8px;
  font-size: 16px;
  font-stretch: var(--fontWidthExtended);
}

.LoginPage__form {
  margin: 48px 0 24px;
}

.LoginPage__fieldset {
  display: grid;
  gap: 12px;
  min-width: 320px;
}

.LoginPage__footer {
  display: grid;
  gap: 24px;
  justify-items: center;
  font-stretch: var(--fontWidthExtended);
  text-align: center;
}

.LoginPage__oauthButton {
  padding: 4px;
  border-radius: 50%;
  background-color: var(--themeNeutral0);

  &:disabled {
    --fillColor: var(--themeNeutral200);
    background-color: var(--themeLightTransparent300);
  }
}
</style>