<template>
  <progress v-if="isLoading" />

  <template v-else-if="player">
    <section v-if="player.user_id">
      <p>"{{player.name}}" is already claimed by someone</p>
    </section>

    <section v-else-if="!currentUser">
      <p>Sign up to claim "{{player.name}}"</p>

      <button 
        @click="handleSignUpClick"
      >Sign up with Google</button>
    </section>

    <section v-else-if="player.email !== currentUser.email">
      <p>The email address for "{{ player.name }}" doesn’t match yours. Contact your commissioner</p>
    </section>

    <section v-else>
      <progress v-if="isUpdatingPlayer" />
      <button 
        v-else
        @click="claimPlayer()"
      >Claim "{{ player.name }}"</button>
    </section>
  </template>

  <section v-else>
    <p>Player doesn’t exist</p>
  </section>
</template>

<script lang="ts" setup>
import { Tables } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import { RouteName } from '@/lib/router/routes';
import useAuthStore from '@/stores/useAuthStore';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter()
const route = useRoute()
const authStore = useAuthStore()
const { signInWithGoogle } = authStore
const {currentUser} = storeToRefs(authStore)

const player = ref<Tables<"players"> | null>(null)

const isLoading = ref(false)
const isUpdatingPlayer = ref(false)

onMounted(() => {
  const playerId = String(route.params.id)

  fetchPlayer(playerId)
})

async function fetchPlayer(playerId:string) {
  isLoading.value = true
  const {data: _player, error} = await supabaseClient
    .from("players")
    .select()
    .eq("id", playerId)
    .limit(1)
    .maybeSingle()
  isLoading.value = false

  player.value = _player
  if (error) alert(error.message)
}

async function claimPlayer() {
  if (!currentUser.value) return alert("Not signed in")
  if (!player.value) return alert("Player is missing")

  const runId = route.query.runId?.toString()

  isUpdatingPlayer.value = true
  const {data: _player, error} = await supabaseClient
    .from("players")
    .update({
      user_id: currentUser.value.id,
    })
    .eq("id", player.value.id)
    .select()
    .maybeSingle()
  isUpdatingPlayer.value = false

  if (error) alert(error.message)

  if (_player && runId) {
    router.push({
      name: RouteName.runInviteAccept,
      params: {
        runId,
        playerId: _player.id
      },
      query: {
        probability: route.query.probability,
      }
    })
  } else if (_player) {
    router.push({
      name: RouteName.index,
    })
  }
}

function handleSignUpClick() {
  signInWithGoogle({
    redirectTo: `${import.meta.env.VITE_APP_URL}${route.fullPath}` // TODO: url util for env
  })
}
</script>

<style lang="scss" scoped>
</style>