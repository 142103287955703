<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="18" cy="18" r="18" fill="#D9D9D9"/>
    <g clip-path="url(#clip0_564_31)">
    <rect x="2" y="2" width="32" height="32" rx="16" fill="#F07702"/>
    <line x1="17.75" y1="0.238525" x2="17.75" y2="37.8165" stroke="#040203" stroke-width="1.5"/>
    <line x1="36.3491" y1="18.25" x2="-1.22886" y2="18.25" stroke="#040203" stroke-width="1.5"/>
    <circle cx="1.70649" cy="18.1468" r="12.1674" stroke="#040203" stroke-width="1.5"/>
    <circle cx="34.0004" cy="18.1468" r="12.1674" stroke="#040203" stroke-width="1.5"/>
    </g>
    <rect x="2.75" y="2.75" width="30.5" height="30.5" rx="15.25" stroke="#040203" stroke-width="1.5"/>
    <defs>
    <clipPath id="clip0_564_31">
    <rect x="2" y="2" width="32" height="32" rx="16" fill="white"/>
    </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts" setup>

</script>

<style lang="scss" scoped>

</style>