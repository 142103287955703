<template>
  <progress v-if="!run" />
  <section 
    v-else-if="currentUser?.email === 'jonnie.hallman@gmail.com'"
    class="CommissionerRunView"
  >
    <table>
      <tbody>
        <tr>
          <th>Crews</th>
          <td>
            <ul>
              <li
                v-for="crew in run.crews"
                :key="crew.id"
              >
                {{ crew.name }}
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th>Season</th>
          <td>
            {{ run.season?.name }}
          </td>
        </tr>
        <tr>
          <th>Court</th>
          <td>
            {{ run.court?.name }}
          </td>
        </tr>
        <tr>
          <th>Date</th>
          <td>
            {{ formatRunDate(run, "America/New_York") }}
          </td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead>
        <tr>
          <th colspan="2">Statuses</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="status in sortedStatuses"
          :key="status.player_id"
        >
          <td>{{ status.player?.name }}</td>
          <td>
            <button
              :data-is-selected="status.probability === 1"
              @click="handleStatusClick(status.player_id, 1)"
            >In</button>
            <button
              :data-is-selected="status.probability === 0.5"
              @click="handleStatusClick(status.player_id, 0.5)"
            >Maybe</button>
            <button
              :data-is-selected="status.probability === 0"
              @click="handleStatusClick(status.player_id, 0)"
            >Out</button>
          </td>
        </tr>
      </tbody>
    </table>
    <table>
      <thead>
        <tr>
          <th colspan="2">Pending</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="player in pendingPlayers"
          :key="player.id"
        >
          <td>{{ player.name }}</td>
          <td>
            <button
              @click="handleStatusClick(player.id, 1)"
            >In</button>
            <button
              @click="handleStatusClick(player.id, 0.5)"
            >Maybe</button>
            <button
              @click="handleStatusClick(player.id, 0)"
            >Out</button>
          </td>
        </tr>
      </tbody>
    </table>
  </section>
  <p v-else>Not signed in as Jonnie</p>
</template>

<script lang="ts" setup>
import { supabaseClient } from '@/lib/database/supabase';
import isTruthy from '@/lib/utils/boolean/isTruthy';
import formatRunDate from '@/lib/utils/date/formatRunDate';
import useAuthStore from '@/stores/useAuthStore';
import { QueryData } from '@supabase/supabase-js';
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute()
const {currentUser} = storeToRefs(useAuthStore())

const fetchRunQuery = supabaseClient.from("runs").select(
  `*, 
  court:courts(*), 
  crews(*, players(*)), 
  statuses(*, player:players(*)),
  season:seasons(*, players(*))
`).limit(1)
const run = ref<QueryData<typeof fetchRunQuery>[0]>()

onBeforeMount(async () => {
  const {data: _run} = await fetchRunQuery.eq("id", route.params.runId).maybeSingle()

  if (_run) {
    run.value = _run
  }
})

const crewPlayers = computed(() => {
  return run.value?.crews.flatMap((crew) => crew.players) || []
})

const allPlayers = computed(() => {
  const combinedPlayers = [
    ...(run.value?.season?.players || []),
    ...(crewPlayers.value),
  ]
  const playerIds = Array.from(new Set(combinedPlayers.map((player) => player.id)))

  return playerIds.map((id) => {
    return combinedPlayers.find((player) => player.id === id)
  }).filter(isTruthy)
})

const pendingPlayers = computed(() => {
  const statusPlayerIds = run.value?.statuses.map((status) => status.player_id) || []

  return allPlayers.value.filter((player) => !statusPlayerIds.includes(player.id))
})

const sortedStatuses = computed(() => {
  return run.value?.statuses.sort((a, b) => {
    return b.probability - a.probability
  }) || []
})

async function handleStatusClick(player_id: string, probability: number) {
  if (!run.value) return

  const existingStatus = run.value.statuses.find((_status) => _status.player_id === player_id)

  if (existingStatus && existingStatus.probability === probability) {
    await supabaseClient.from("statuses")
      .delete()
      .eq("run_id", run.value.id)
      .eq("player_id", player_id)

    run.value.statuses.splice(run.value.statuses.indexOf(existingStatus), 1)
  } else {
    const {data: status} = await supabaseClient.from("statuses").upsert({
      run_id: run.value.id,
      player_id,
      probability,
    }).select("*, player:players(*)").maybeSingle()

    if (!status) return
  
    if (existingStatus) {
      Object.assign(existingStatus, status)
    } else {
      run.value.statuses.push(status)
    }
  }
}
</script>

<style lang="scss" scoped>
.CommissionerRunView {
  display: grid;
  gap: 16px;
  align-content: start;
  padding: 16px;
  overflow-y: auto;
  height: 100%;
  background-color: var(--themeNeutral0);
}

table {
  width: 100%;
  border-collapse: collapse;
}

table, 
th, 
td {
  border: 1px solid black;
  text-align: left;
}

th,
td {
  padding: 12px;
}

th {
  font-weight: var(--fontWeightMedium);
}

button {
  padding: 4px 8px;
  border: 1px solid black;

  & + & {
    margin-left: 8px;
  }

  &[data-is-selected="true"] {
    background-color: black;
    color: white;
  }
}
</style>