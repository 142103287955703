<template>
  <Logo 
    v-if="!isLoaded" 
    class="App__logo"
  />
  <RouterView v-else />
</template>

<script lang="ts" setup>
import Logo from '@/components/Logo.vue';
import { RouteName } from '@/lib/router/routes';
import useAuthStore from '@/stores/useAuthStore';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()
const authStore = useAuthStore()
const {isSignedIn} = storeToRefs(authStore)
const {subscribeToAuthChanges} = authStore
const isLoaded = ref(false)

subscribeToAuthChanges().then(() => isLoaded.value = true)

watch(isSignedIn, () => {
  if (!isSignedIn.value) {
    router.push({name: RouteName.login})
  }
})
</script>

<style lang="scss" scoped>
.App__logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
