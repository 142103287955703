import { supabaseClient } from "@/lib/database/supabase";
import { QueryData } from "@supabase/supabase-js";
import { defineStore } from "pinia";
import { ref } from "vue";

export default defineStore("RunStore", () => {
  const runsWithCourtsAndCrewsQuery = supabaseClient.from("runs").select("*, crews(*), court:courts(*), statuses(*)").order("start_at", {ascending: false})
  type RunsWithCourtsAndCrews = QueryData<typeof runsWithCourtsAndCrewsQuery>
    
  const runs = ref<RunsWithCourtsAndCrews>([])

  async function fetchRuns() {
    const {data: _runs} = await runsWithCourtsAndCrewsQuery
  
    if (_runs) runs.value = _runs
  }

  return {
    runs,
    fetchRuns,
  }
})