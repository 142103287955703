<template>
  <section 
    class="ScheduleView"
    ref="scheduleViewRef"
  >
    <ListGroup
      v-for="monthGroup in groupedRunItems"
      :key="monthGroup.datetime"
      :label="monthGroup.label"
    >
      <TimelineRunGroup 
        v-for="group in monthGroup.groups"
        :key="group.datetime"
        :date="group.date"
      >
        <TimelineRunItem
          v-for="item in group.items"
          :key="item.run.id"
          :modelValue="item"
        />
      </TimelineRunGroup>
    </ListGroup>
  </section>
</template>

<script lang="ts" setup>
import ListGroup from '@/components/ListGroup.vue';
import { listHeaderHeight } from '@/components/ListHeader.types';
import TimelineRunGroup from '@/components/TimelineRunGroup.vue';
import { RunItem } from '@/components/TimelineRunItem.types';
import TimelineRunItem from '@/components/TimelineRunItem.vue';
import { Tables } from '@/lib/database/database.types';
import { supabaseClient } from '@/lib/database/supabase';
import useRunStore from '@/stores/useRunStore';
import { RealtimeChannel, RealtimePostgresDeletePayload, RealtimePostgresInsertPayload, RealtimePostgresUpdatePayload } from '@supabase/supabase-js';
import { format, isFuture, isSameDay, isSameMonth, isThisYear, parseISO, startOfDay, startOfMonth } from 'date-fns';
import { storeToRefs } from 'pinia';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, useTemplateRef } from 'vue';

const runStore = useRunStore()
const {fetchRuns} = runStore
const {runs} = storeToRefs(runStore)

const statusChannel = ref<RealtimeChannel | null>(null);

const today = startOfDay(new Date)
const scheduleViewRef = useTemplateRef("scheduleViewRef")

onMounted(async () => {
  if (runs.value.length > 0) {
    scrollToCurrentRunGroup()
  }

  try {
    await fetchRuns()
    await nextTick()
    scrollToCurrentRunGroup()
  } catch (err) {
    alert((err as Error).message)
  }

  statusChannel.value = supabaseClient
    .channel("statuses")
    .on("postgres_changes", { event: "INSERT", schema: "public", table: "statuses" }, handleStatusUpsert)
    .on("postgres_changes", { event: "UPDATE", schema: "public", table: "statuses" }, handleStatusUpsert)
    .on("postgres_changes", { event: "DELETE", schema: "public", table: "statuses" }, handleStatusDelete)
    .subscribe();
})

onBeforeUnmount(() => {
  statusChannel.value?.unsubscribe();
  statusChannel.value = null;
})

const statuses = computed(() => {
  return runs.value?.flatMap((run) => run.statuses) || []
})

const runItems = computed(():Array<RunItem> => {
  return runs.value?.map((run) => {
    return {
      run,
      court: run.court!,
      crews: run.crews,
      statuses: run.statuses,
    }
  }) || []
})

const currentRunGroup = computed(() => {
  const runGroups = groupedRunItems.value.flatMap(({groups}) => groups)

  return runGroups.reverse().find(({date}) => isSameDay(date, today) || isFuture(date))
})

const groupedRunItems = computed(() => {
  return runItems.value.reduce<Array<{
    label: string, 
    date: Date, 
    datetime: string,
    groups: [
      {
        date: Date, 
        datetime: string,
        items: Array<RunItem>,
      }
    ]
  }>>((acc, runItem) => {
    const runDate = parseISO(runItem.run.start_at)
    const lastMonthGroup = acc.at(-1)

    if (!lastMonthGroup || !isSameMonth(runDate, lastMonthGroup.date)) {
      const groupDate = startOfDay(runDate)
      
      acc.push({
        label: format(runDate, isThisYear(runDate) ? "MMMM" : "MMMM yyyy"),
        date: startOfMonth(runDate),
        datetime: runItem.run.start_at,
        groups: [
          {
            date: groupDate,
            datetime: groupDate.toISOString(),
            items: [runItem]
          }
        ]
      })
    } else if (!isSameDay(runDate, lastMonthGroup.groups.at(-1)!.date)) {
      const groupDate = startOfDay(runDate)

      lastMonthGroup.groups.push({
        date: groupDate,
        datetime: groupDate.toISOString(),
        items: [runItem]
      })
    } else {
      lastMonthGroup.groups.at(-1)?.items.push(runItem)
    }

    return acc
  }, [])
})

function scrollToCurrentRunGroup () {
  if (currentRunGroup.value) {
    const runGroupEl = scheduleViewRef.value?.querySelector(`.TimelineRunGroup[data-datetime='${currentRunGroup.value.datetime}']`)
    if (!runGroupEl) return
    
    document.querySelector(".AppLayout__main")!.scrollTo({
      top: (runGroupEl as HTMLElement).offsetTop
        - document.querySelector(".AppLayout__main")!.getBoundingClientRect().top 
        - listHeaderHeight
    })
  }
}

function addOrReplaceStatus(status:Tables<"statuses">) {
  const existingStatus = statuses.value.find((_status) => _status.run_id === status.run_id && _status.player_id == status.player_id)

  if (existingStatus) {
    Object.assign(existingStatus, status)
  } else {
    const run = runs.value.find((run) => run.id === status.run_id)
  
    if (run) run.statuses.push(status)
  }
}

function removeStatus(status:Partial<Tables<"statuses">>) {
  const run = runs.value.find((run) => run.id === status.run_id)
  if (!run) return;

  const index = run.statuses.findIndex((_status) => _status.run_id === status.run_id && _status.player_id === status.player_id)
  
  if (index !== -1) run.statuses.splice(index, 1)
}

function handleStatusUpsert (payload:RealtimePostgresInsertPayload<Tables<"statuses">> | RealtimePostgresUpdatePayload<Tables<"statuses">>) {
  addOrReplaceStatus(payload.new)
}

function handleStatusDelete (payload:RealtimePostgresDeletePayload<Tables<"statuses">>) {
  removeStatus(payload.old)
}
</script>

<style lang="scss" scoped>
.ScheduleView {
  background-color: var(--colorNeutral0);
  padding-bottom: 143px;
  height: 100%;
}
</style>